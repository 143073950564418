

























import Vue from 'vue';
import { alertService } from '@/services/alertService';
import Icon from '@/components/atoms/icon/Icon.vue';

export default Vue.extend({
    name: 'AppAlerts',
    components: {
        Icon,
    },
    data() {
        return {
            alertService,
        };
    },
});
