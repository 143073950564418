import Vue from 'vue';
import { MessageType } from '@/enums/messageType';
import { interpolate, InterpolationPlaceholders } from './interpolationService';
import { messageService } from './messageService';
export const alertDisplayDuration = 5000;

export interface Alert {
    readonly type: MessageType;
    readonly message: string;
}

export interface AlertWithIdentifier {
    readonly type: MessageType;
    readonly identifier: string;
}

export interface AlertOptions {
    alert: Alert | AlertWithIdentifier;
    placeholders?: InterpolationPlaceholders;

    /** Duration to display the message in milliseconds, defaults to {@link alertDisplayDuration} */
    displayDuration?: number;
}

class AlertService {
    private readonly state = Vue.observable({
        alerts: [] as Alert[],
    });

    private async getAlertMessage(
        alert: Alert | AlertWithIdentifier,
        placeholders: InterpolationPlaceholders,
    ): Promise<string> {
        if ('message' in alert) {
            return interpolate(alert.message, placeholders);
        }

        if (alert.type === MessageType.error) {
            return messageService.getErrorMessage(
                alert.identifier,
                placeholders,
            );
        }

        return messageService.getInfoMessage(alert.identifier, placeholders);
    }

    private async qualifyAlert(
        alert: Alert | AlertWithIdentifier,
        placeholders: InterpolationPlaceholders,
    ): Promise<Alert> {
        return {
            type: alert.type,
            message: await this.getAlertMessage(alert, placeholders),
        };
    }

    /**
     * Adds an additional alert, which are displayed at the top of the currently opened form
     * @param options
     */
    public async pushAlert(options: AlertOptions): Promise<void> {
        const qualifiedAlert = await this.qualifyAlert(
            options.alert,
            options.placeholders ?? {},
        );
        this.state.alerts.push(qualifiedAlert);

        options.displayDuration =
            options.displayDuration ?? alertDisplayDuration;

        if (options.displayDuration > 0) {
            setTimeout(
                () => this.removeAlert(qualifiedAlert),
                options.displayDuration,
            );
        }
    }

    private removeAlert(alert: Alert): void {
        this.state.alerts = this.state.alerts.filter(
            (stateAlert) => stateAlert !== alert,
        );
    }

    /** Remove all currently active alerts */
    public clear(): void {
        this.state.alerts = [];
    }

    /** Get all currently active alerts  */
    public get alerts(): Alert[] {
        // latest alerts should be shown first
        return [...this.state.alerts].reverse();
    }
}

export const alertService = new AlertService();
